import {Module} from "vuex"
import {RootState} from "../types"
import {Communication, Document, Message} from "./types"
import diff, {Diff} from "lib/date/diff"

const communicationState: Communication = {
	messages: [],
	documents: [],
	modules: []
}

const communication: Module<Communication, RootState> = {
	getters: {
		unreadMessages: state => state.messages.reduce((count, message) => count + (!message.read ? 1 : 0), 0),
		unreadDocuments: state => state.documents.reduce((count, document) => count + (!document.downloaded ? 1 : 0), 0),
		messages: state => [...state.messages].sort((a, b) => diff(Diff.Millis, a.date, b.date)),
		documents: state => [...state.documents].sort((a, b) => diff(Diff.Millis, a.date, b.date)),
		modules: state => [...state.modules]
	},
	mutations: {
		hydrate(state, data: Communication) {
			Object.assign(state, data)
		},
		messageRead(state, message: Message) {
			if (state.messages.includes(message)) {
				message.read = true
			}
		},
		documentDownloaded(state, document: Document) {
			if (state.documents.includes(document)) {
				document.downloaded = true
			}
		}
	},
	actions: {
		messageRead(context, payload: Message) {
			if (!payload.read) {
				context.commit("messageRead", payload)
			}
		},
		downloadDocument(context, payload: Document) {
			context.commit("documentDownloaded", payload)
		}
	},
	namespaced: true,
	state: communicationState
}

export default communication
