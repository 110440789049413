import {AuthorizationStrategy} from "lib/types/security"
import eventBus from "lib/vue/eventBus"

export default class Session<TCredentials> {

	constructor(private readonly authorization: AuthorizationStrategy<TCredentials>) {}

	start(roles?: ReadonlyArray<string>): void {
		if (this.authorization.isLoggedIn()) {
			if (roles && roles.length && !this.authorization.isAuthorizedAny(roles)) {
				eventBus.$emit("access-denied")
			}
		} else {
			eventBus.$emit("no-session")
		}
	}

	async login(credentials: TCredentials): Promise<boolean> {
		const success = await this.authorization.login(credentials)
		eventBus.$emit(success ? "session-started" : "failed-login")
		return success
	}

	async logout(): Promise<boolean> {
		const success = await this.authorization.logout()
		if (success) {
			eventBus.$emit("session-ended")
		}
		return success
	}

}
