import {get} from "../request"
import {rootEndPoint} from "../settings"
import {Asset, BasicAsset} from "../models/asset"

const assetsURL = `${rootEndPoint}/assets`

export interface AssetController {
	getMany: (skip?: number, limit?: number) => Promise<Array<BasicAsset>>
	getById: (assetId: string) => Promise<Asset>
}

export default class AssetGateway implements AssetController {
	async getMany(skip?: number, limit?: number): Promise<Array<BasicAsset>> {
		return await get(`${assetsURL}`, {skip, limit})
	}
	async getById(assetId: string): Promise<Asset> {
		return await get(`${assetsURL}/${assetId}`)
	}
}
