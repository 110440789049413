import {get} from "lodash-es"
import Vue from "vue"

/**
 * Renders the mustache and applies Vue filters.
 *
 * @argument {string} mustache The contents of the mustache to be rendered.
 * @argument {any} context The context to get values from.
 * @returns {string}
 */
const render = (mustache: string, context: any): string => {
	const pipeline = mustache.split("|").map(s => s.trim())
	if (pipeline.length === 0) {
		throw new RangeError("Empty mustache")
	}
	const value = get(context, pipeline[0]) || `{{ ${pipeline[0]}: undefined }}`
	if (pipeline.length === 1) {
		return value.toString()
	}

	// Process the filters. At this point, only filters without arguments are supported.
	return pipeline.slice(1).reduce((result, filter) => Vue.filter(filter)(result), value)
}

export default (template: string, context: any) => {
	const mustaches = []
	const matcher = /\{\{[ ]?([A-Z0-9\.]+)[ ]?}}/gi
	let result: RegExpExecArray | null
	// tslint:disable-next-line:no-conditional-assignment - otherwise we have to check twice.
	while (result = matcher.exec(template)) {
		mustaches.push(result)
	}

	return mustaches.reverse().reduce((content, [match, field]) => content.replace(match, render(field, context)), template)
}
