export const hexToRgb = (hex: string): readonly [number, number, number] => {
	const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	if (match) {
		return [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)]
	} else {
		throw Error(`Invalid hex: ${hex}`)
	}
}

export const rgbToHsl = (rgb: readonly [number, number, number]): readonly [number, number, number] => {
	const [r, g, b] = rgb.map(x => x / 255)

	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	let h = (max + min) / 2
	let s = h
	const l = h

	if (max === min) {
		h = s = 0
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

		switch (max) {
			case r: h = (g - b) / d + (g < b ? 6 : 0); break
			case g: h = (b - r) / d + 2; break
			case b: h = (r - g) / d + 4; break
		}

		h /= 6
	}
	return [h, s, l]
}

export const hslToRgb = (hsl: readonly [number, number, number]): readonly [number, number, number] => {
	const [h, s, l] = hsl

	const q = l < 0.5 ? l * (1 + s) : l + s - l * s
	const p = 2 * l - q

	const r = hueToRgb(p, q, h + 1 / 3)
	const g = hueToRgb(p, q, h)
	const b = hueToRgb(p, q, h - 1 / 3)

	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

const hueToRgb = (p: number, q: number, t: number): number => {
	if (t < 0) { t += 1 }
	if (t > 1) { t -= 1 }
	if (t < 1 / 6) { return p + (q - p) * 6 * t }
	if (t < 1 / 2) { return q }
	if (t < 2 / 3) { return p + (q - p) * (2 / 3 - t) * 6 }
	return p
}

const componentToHex = (c: number): string => c.toString(16).padStart(2, "0")

export const rgbToHex = (rgb: readonly [number, number, number]): string => `#${rgb.map(x => componentToHex(x)).join("")}`
export const hexToHsl = (hex: string) => rgbToHsl(hexToRgb(hex))
export const hslToHex = (hsl: readonly [number, number, number]) => rgbToHex(hslToRgb(hsl))
