import always from "lib/function/always"
import keys from "lib/misc/keys"
import {ConversionMap, ConversionFunction} from "lib/types/import"
import {Predicate, Function1} from "lib/types/function"
import {get, isArray, isNil, isPlainObject} from "lodash-es"

/**
 * Extracts fields from the data object and produces a `T`.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {any} data The incoming data object.
 * @returns {Partial<T>} The object with converted values.
 */
export const extract = <T>(map: ConversionMap<T>, data: any): T =>
	Object.fromEntries(
		keys(map).map(field => {
			const [path, convert, required] = map[field]
			const value = get(data, path)
			const result = convert(value)
			if ((required || !isNil(value)) && result === undefined) {
				throw new TypeError(`Field [${path}] ${required ? "does not exist or" : ""} contains an improper value.`)
			}
			return [field, result as any] // FIXME: improve cast or remove completely.
		})
	)

/**
 * Curried version of `extract`.
 */
export const one = <T>(map: ConversionMap<T>): Function1<any, T> => (data: any) => extract(map, data)

/**
 * Returns a `ConversionFunction` that converts an incoming array to an array of `T`'s.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {Predicate<T>} predicate Optional predicate function, to filter the incoming array before conversion.
 * @returns {ConversionFunction<Array<T>>} The conversion function.
 */
export const many = <T>(
	mapOrConvert: ConversionMap<T> | ConversionFunction<T>,
	predicate: Predicate<any> = always
): ConversionFunction<Array<T>> => {
	const convert = typeof mapOrConvert === "function" ? mapOrConvert : one(mapOrConvert)
	return (values: any) => isArray(values) ? values.filter(predicate).map(mandatory(convert)) : undefined
}

/**
 * Returns a `ConversionFunction` that converts only the last element of an incoming array to an instance of type `T`.
 *
 * @argument {ConversionMap<T>} map The conversion map.
 * @argument {Predicate<T>} predicate Optional predicate function, to filter the incoming array before conversion.
 * @returns {ConversionFunction<T>} The conversion function.
 */
export const last = <T>(map: ConversionMap<T>, predicate: Predicate<any> = always): ConversionFunction<T> =>
	(values: any) => {
		const array = isArray(values) && values.filter(predicate)
		if (array && array.length) {
			return extract(map, array[array.length - 1])
		}
		return undefined
	}

/**
 * Returns a `ConversionFunction` that converts an object's values.
 *
 * @argument {ConversionFunction<V>} convert The function to convert individual values in the object.
 * @returns {ConversionFunction<Record<keyof K, V>>} The conversion function.
 */
export const obj = <K, V>(convert: ConversionFunction<V>): ConversionFunction<Record<keyof K, V>> =>
	data => {
		if (isPlainObject(data)) {
			const result: Partial<Record<keyof K, V>> = {}
			for (const key of keys<K>(data)) {
				result[key] = convert(data[key])
			}
			return result as Record<keyof K, V>
		}
		return undefined
	}

/**
 * Returns a `ConversionFunction` that only converts the value if it is not `undefined`.
 *
 * @argument {ConversionFunction<T>} f
 * @returns {ConversionFunction<T>}
 */
export const optional = <T>(f: ConversionFunction<T>): ConversionFunction<T> =>
	(data: any) => data === undefined ? undefined : f(data)

/**
 * Returns a function that enforces that the value can be converted, or throws an error.
 */
export const mandatory = <T>(f: ConversionFunction<T>): Function1<any, T> =>
	(data: any) => {
		const result = f(data)
		if (result === undefined) {
			throw new TypeError(`Missing value or cannot convert: ${JSON.stringify(data)}`)
		}
		return result
	}
