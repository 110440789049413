import Vue, { VueConstructor } from "vue"
import Component from "vue-class-component"

export default (...types: Array<VueConstructor>) => {
	@Component
	class ChildComponents extends Vue {
		children = this.$children // TODO: this.$children has no guaranteed order.
		observer!: MutationObserver

		mounted() {
			this.observer = new MutationObserver(this.inspect)
			// Usually, the child components are initialized before the parent (this component).
			const element = this.$children.length ? this.$children[0].$el.parentElement! : this.$el
			this.observer.observe(element, { childList: true })
			this.inspect()
		}

		beforeDestroy() {
			this.observer.disconnect()
		}

		inspect() {
			const properTypes = this.$children.every(
				child => types.some(type => child instanceof type)
			)
			if (!properTypes) {
				throw new TypeError("Invalid child component")
			}
			this.$emit("childcomponents:changed", this.children)
		}
	}

	return ChildComponents
}
