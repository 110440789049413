import {get} from "../request"
import {rootEndPoint} from "../settings"
import {Employer} from "../models/employer"

const employerURL = `${rootEndPoint}/employer`
const messagesURL = `${rootEndPoint}/messages`
const documentsURL = `${rootEndPoint}/documents`
const modulesURL = `${rootEndPoint}/modules`

export interface EmployerController {
	get: (employerId: string) => Promise<Employer>
}

export default class EmployerGateway implements EmployerController {
	async get(): Promise<Employer> {
		return await get(employerURL)
	}
	async documents(): Promise<ReadonlyArray<any>> {
		return await get(documentsURL)
	}
	async messages(): Promise<ReadonlyArray<any>> {
		return await get(messagesURL)
	}

	async modules(): Promise<ReadonlyArray<any>> {
		return await get(modulesURL)
	}
}
