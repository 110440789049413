
// Tijdelijk ACC fixed voor ICS-751 testing
export const rootEndPoint = "https://mercer-portal-api.icst-keylane.com"
export const loginEndPoint = "https://mercer-authentication.icst-keylane.com/api/login"
export const refreshTokenEndPoint = "https://mercer-authentication.icst-keylane.com/oauth/access_token"


// PROD
// export const rootEndPoint = "https://mercer-portal-api.ics-keylane.com"
// export const loginEndPoint = "https://mercer-authentication.ics-keylane.com/api/login"
// export const refreshTokenEndPoint = "https://mercer-authentication.ics-keylane.com/oauth/access_token"
