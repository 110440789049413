


























import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {State} from "vuex-class"

import CrumbPath from "custom/CrumbPath.vue"
import PageHeader from "custom/PageHeader.vue"
import PageFooter from "custom/PageFooter.vue"
import SubscriptionModal from "custom/modal/SubscriptionModal.vue"

import event from "lib/vue/event"
import eventBus from "lib/vue/eventBus"

import {Introductions} from "../mercerAPI/models/content"

@Component({
	components: {
		CrumbPath,
		PageHeader,
		PageFooter,
		SubscriptionModal
	}
})
export default class Page extends Vue {
	@State introductions!: Introductions
	@Prop({type: String, required: false, default: ""}) title!: string
	@Prop({type: String, required: false, default: ""}) intro!: string

	loaded = false
	modal = ""

	get introduction() {
		const introduction = this.introductions[this.intro]
		if (!introduction) {
			return undefined
		}
		// Handle event links of the form '[event](content)'.
		const match = introduction.match(/\[(.*)\]\((.*)\)/)
		if (match) {
			const [completeMatch, emit, content] = match
			return introduction.replace(completeMatch, `<a href="javascript:void(0)" data-emit="${emit}">${content}</a>`)
		}
		return introduction
	}

	async mounted() {
		await event("loaded", 5000)
		this.loaded = true
		this.$nextTick(() => {
			for (const link of this.$el.querySelectorAll("[data-emit]")) {
				link.addEventListener("click", () => eventBus.$emit((link as HTMLElement).dataset.emit!))
			}
		})
	}
}
