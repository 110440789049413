import RequestStrategy from "./RequestStrategy"
import {AuthorizationStrategy} from "lib/types/security"
import eventBus from "lib/vue/eventBus"
import {RequestMethod, Parameters} from "lib/types/request"
import RequestError from "./RequestError"

const unauthorized = new Set([401, 403])

const errorHandler = (error: Error) => {
	if (error instanceof RequestError && unauthorized.has(error.response.status)) {
		eventBus.$emit("unauthorized")
	}

	eventBus.$emit("gatewayerror", error)
	return Promise.reject(error)
}

export default class Gateway implements RequestStrategy {

	constructor(private readonly authorization: AuthorizationStrategy<any>) {}

	private async fetch(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		return this.authorization.request(method, input, data).catch(errorHandler)
	}

	async get(input: string, data?: Parameters): Promise<Response> {
		return this.fetch("GET", input, data)
	}

	async post(input: string, data?: Parameters): Promise<Response> {
		return this.fetch("POST", input, data)
	}

}
