import JwtAuthorization from "lib/security/jwt/JwtAuthorization"
import Gateway from "lib/request/Gateway"
import Session from "lib/security/Session"
import {Parameters} from "lib/types/request"
import {loginEndPoint, refreshTokenEndPoint} from "./settings"
import {storageOptions} from "lib/storage/session"
import eventBus from "lib/vue/eventBus"

const authorization = new JwtAuthorization(loginEndPoint, refreshTokenEndPoint, storageOptions)

export const gateway = new Gateway(authorization)
export const session = new Session(authorization)

if (!process.env.SERVER) {
	eventBus.$on("no-session", () => {
		location.href = "./login.html"
	})
}

// Convenience function to prevent code repetition.
export const get = async <T>(input: string, data?: Parameters): Promise<T> => {
	const response = await gateway.get(input, data)
	return await response.json()
}
