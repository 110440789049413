import {get} from "../request"
import {rootEndPoint} from "../settings"
import {Introductions} from "../models/content"

const contentURL = (locale: string) => `${rootEndPoint}/content/${locale}`

export interface ContentController {
	getMany: (locale: string) => Promise<Introductions>
}

export default class ContentGateway implements ContentController {
	async getMany(locale: string): Promise<Introductions> {
		return await get(contentURL(locale))
	}
}
