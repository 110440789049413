import {VueConstructor} from "vue"
import {Options} from "lib/types/i18n"
import dayFormatter from "lib/i18n/format/day"
import monthFormatter from "lib/i18n/format/month"
import dateFormatter from "lib/i18n/format/date"
import numberFormatter from "lib/i18n/format/number"
import moneyFormatter from "lib/i18n/format/money"
import template from "lib/misc/template"
import round from "lib/number/round"
import truncate from "lib/number/truncate"
import {get} from "lodash-es"

// tslint:disable-next-line:variable-name - allow uppercase `Vue`.
export default (Vue: VueConstructor, options?: Options) => {
	if (!options) {
		throw new ReferenceError("Options are required")
	}

	Vue.filter("locale", (code: string) => get(options.texts, code) || `{{ ${code}: undefined }}`)
	Vue.filter("template", template)

	// Create filters for display of dates and numbers.
	const locale = options.locale

	const formatNumber = numberFormatter(locale)
	const formatDate = dateFormatter(locale)
	const formatDay = dayFormatter(locale)
	const formatMonth = monthFormatter(locale)
	const formatMoney = moneyFormatter(locale)

	Vue.filter("truncate", truncate)
	Vue.filter("round", (n: number, decimals: number = 0) => round(decimals, n))
	Vue.filter("number", formatNumber)
	Vue.filter("money", formatMoney)
	Vue.filter("longDate", formatDate("long"))
	Vue.filter("shortDate", formatDate("short"))
	Vue.filter("day", formatDay("long"))
	Vue.filter("month", formatMonth("long"))

	// Create filters for number conjugations.
	for (const [code, [singular, plural]] of Object.entries(options.plural)) {
		Vue.filter(code, (n: number) => `${n} ` + (n === 1 ? singular : plural))
	}
}
