import {Module} from "vuex"
import {RootState} from "../types"
import {User, SchemeCode} from "./types"

const userState: User = {
	name: "",
	email: "",
	position: "",
	employer: "",
	schemes: []
}

const user: Module<User, RootState> = {
	getters: {
		schemeCodes: (state, _getters, _rootState): Array<SchemeCode> => state.schemes
			.map(scheme => ({name: `${scheme.provider.name} - ${scheme.name}`, code: scheme.code}))
	},
	mutations: {
		hydrate(state, data: User) {
			Object.assign(state, data)
		},
		email(state, value: string) {
			state.email = value
		}
	},
	actions: {
		email(context, payload: string) {
			context.commit("email", payload)
		},
		hydrate(context, payload: User) {
			context.commit("hydrate", payload)
		}
	},
	namespaced: true,
	state: userState
}

export default user
