/**
 * Performs a `reduce` operation on the iterable, retaining the intermediate results.
 */
export default <T, U>(f: (accumulator: U, element: T) => U, initial: U, iterable: Iterable<T>): ReadonlyArray<U> => {
	const span: Array<U> = []
	let result = initial
	for (const value of iterable) {
		result = f(result, value)
		span.push(result)
	}
	return span
}
