import {get} from "../request"
import {rootEndPoint} from "../settings"
import {BasicLifecycle, Lifecycle, LifecycleReturn} from "../models/lifecycle"

const lifecycleURL = `${rootEndPoint}/lifecycles`

export interface LifecycleController {
	getMany: (skip?: number, limit?: number) => Promise<Array<BasicLifecycle>>
	getById: (lifecycleId: string) => Promise<Lifecycle>
	getPerformance: (lifecycleId: string, fromYear: number, toYear: number, ages?: Array<number>) => Promise<Array<LifecycleReturn>>
}

export default class LifecycleGateway implements LifecycleController {
	async getMany(skip?: number, limit?: number): Promise<Array<BasicLifecycle>> {
		return await get(`${lifecycleURL}`, {skip, limit})
	}
	async getById(lifecycleId: string): Promise<Lifecycle> {
		return await get(`${lifecycleURL}/${lifecycleId}`)
	}
	async getPerformance(lifecycleId: string, fromYear: number, toYear: number, ages?: Array<number>): Promise<Array<LifecycleReturn>> {
		return await get(`${lifecycleURL}/${lifecycleId}/performance/${fromYear}/${toYear}`, {ages})
	}
}
