
import Component, { mixins } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { Line } from "vue-chartjs"
import { GraphContent } from "custom/charts/types"

@Component
export default class LineChart extends mixins(Line) {
	@Prop({type: Object, required: true}) graphcontent!: GraphContent

	@Watch("graphcontent")
	loadContent() {
		this.renderChart({
			labels: this.graphcontent.labels,
			datasets: this.graphcontent.datasets
		},
		{
			title: {
				display: true,
				text: this.graphcontent.title,
				fontSize: 16
			},
			tooltips: {
				mode: "index",
				intersect: false,
				itemSort: (a: any, b: any) => b.datasetIndex - a.datasetIndex,
				callbacks: {
					label: (tooltipItems: any, data: any) =>
						// tslint:disable-next-line:max-line-length
						`${data.datasets[tooltipItems.datasetIndex].label}: ${this.graphcontent.callback ? this.graphcontent.callback(tooltipItems.yLabel) : tooltipItems.yLabel}`
				}
			},
			responsive: true,
			scales: {
				xAxes: [{
					stacked: true
				}],
				yAxes: [{
					stacked: true,
					display: true,
					ticks: {
						beginAtZero: true,
						steps: 10,
						stepValue: 5,
						max: 100,
						callback: (value: number): string => `${this.graphcontent.callback ? this.graphcontent.callback(value) : value}`
					}
				}]
			},
			elements: {
				point: {
					radius: 0
				}
			},
			maintainAspectRatio: false,
			legend: {
				position: "right",
				reverse: true,
				onClick: null
			}
		})
	}

	mounted() {
		this.loadContent()
	}
}
