import {get} from "../request"
import {rootEndPoint} from "../settings"
import {BasicScheme, Scheme, Totals, PremiumPrognosis} from "../models/scheme"
import {BasicAsset, Asset} from "../models/asset"
import {Lifecycle, LifecyclePerformanceAges} from "../models/lifecycle"

const schemeURL = `${rootEndPoint}/schemes`

export interface SchemeController {
	getMany: () => Promise<Array<BasicScheme>>
	getByCode: (schemeCode: string) => Promise<Scheme>
	getTotals: (schemeCode: string) => Promise<Totals>
	getAssets: (schemeCode: string) => Promise<Array<BasicAsset>>
	getPremiumPrognosis: (schemeCode: string) => Promise<Array<PremiumPrognosis>>
}

export default class SchemeGateway implements SchemeController {
	async getMany(): Promise<Array<BasicScheme>> {
		return await get(schemeURL)
	}
	async getByCode(schemeCode: string): Promise<Scheme> {
		const data = await get<any>(`${schemeURL}/${schemeCode}`)
		data.referenceDate = new Date(data.referenceDate)
		data.startDate = new Date(data.startDate)
		data.expirationDate = data.expirationDate && new Date(data.expirationDate)
		return data
	}
	async getTotals(schemeCode: string): Promise<Totals> {
		const data = await get<any>(`${schemeURL}/${schemeCode}/totals`)
		data.referenceDate = data.referenceDate
			? new Date(data.referenceDate)
			: undefined
		return data
	}
	async getLifecycles(schemeCode: string): Promise<Array<Lifecycle>> {
		return await get(`${schemeURL}/${schemeCode}/lifecycles`)
	}
	async getPerformance(schemeCode: string, years: number): Promise<Array<LifecyclePerformanceAges>> {
		return await get(`${schemeURL}/${schemeCode}/lifecycles/${years}/performance`)
	}
	async getAssets(schemeCode: string): Promise<Array<Asset>> {
		return await get(`${schemeURL}/${schemeCode}/assets`)
	}
	async getPremiumPrognosis(schemeCode: string): Promise<Array<PremiumPrognosis>> {
		return await get(`${schemeURL}/${schemeCode}/premium/prognosis`)
	}
}
