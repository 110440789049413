import AssetGateway from "src/mercerAPI/controllers/AssetController"
import EmployerGateway from "src/mercerAPI/controllers/EmployerController"
import EmployeeGateway from "src/mercerAPI/controllers/EmployeeController"
import ContentGateway from "src/mercerAPI/controllers/ContentController"
import LifecycleGateway from "src/mercerAPI/controllers/LifecycleController"
import ProviderGateway from "src/mercerAPI/controllers/ProviderController"
import SchemeGateway from "src/mercerAPI/controllers/SchemeController"

export const assets = new AssetGateway()
export const employers = new EmployerGateway()
export const employees = new EmployeeGateway()
export const content = new ContentGateway()
export const lifecycles = new LifecycleGateway()
export const providers = new ProviderGateway()
export const schemes = new SchemeGateway()
