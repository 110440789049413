import Vue from "vue"

export default <T>(emitter: Vue, event: string, timeout: number = Infinity): Promise<T> => new Promise(
	(resolve, reject) => {
		const timer: any = timeout < Infinity
			? setTimeout(reject, timeout)
			: undefined
		emitter.$once(event, (payload: T) => {
			clearTimeout(timer)
			resolve(payload)
		})
	}
)
