









import Vue from "vue"
import Component from "vue-class-component"
import Modal from "components/modal/Modal.vue"

@Component({
	components: {
		Modal
	}
})
export default class SubscriptionModal extends Vue {}
