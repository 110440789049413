import age from "lib/number/age"
import hexSpectrum from "lib/color/hexSpectrum"
import Vue from "vue"

const baseHexPrimary = "#006d9e"
const endHexPrimary = "#edf9fc"
const baseHexSecondary = "#002c77"
const endHexSecondary = "#FFDDAC"
export const colors = (n: number) => hexSpectrum(n, baseHexPrimary, endHexPrimary)
export const secondaryColors = (n: number) => hexSpectrum(10, baseHexSecondary, endHexSecondary).slice(0, n)

export const formatDate = (date: Date) => Vue.filter("shortDate")(date)
export const formatNumber = (n: number, decimals: number = 0) => Vue.filter("number")(n, decimals)
export const formatMoney = (n: number) => Vue.filter("money")(n)
export const formatPercentage = (n: number, decimals: number = 1) => formatNumber(n, decimals) + "%"
export const formatDuration = (totalMonths: number) => {
	const {years, months} = age(totalMonths / 12)
	return (years > 0 ? Vue.filter("years")(years) + " " : "") + (months > 0 ? Vue.filter("months")(months) : "")
}
export const formatPeriod = (years: number) => Vue.filter("years")(years)

export const periods = (base: Array<number>, startAtZero?: boolean) => base.map((n, i) =>
		i === 0 ? (base.length > 1 ? (startAtZero ? `${n} - ${formatPeriod(base[i + 1])}` : `< ${formatPeriod(base[i + 1])}`) : formatPeriod(base[0]))
	: i === base.length - 1 ? `${formatPeriod(n)} >`
	: `${n} - ${formatPeriod(base[i + 1])}`)
