






import Vue from "vue"
import Component from "vue-class-component"

import childComponents from "components/mixin/ChildComponents"
import AccordionItem from "./AccordionItem.vue"

@Component({
	mixins: [
		childComponents(AccordionItem)
	]
})
export default class AccordionList extends Vue {
}
