
























































import Vue from "vue"
import Component from "vue-class-component"
import {Action, namespace, State} from "vuex-class"

import {StoreAction} from "lib/types/vuex"

import ActionButton from "components/button/ActionButton.vue"
import NavigationBar from "custom/NavigationBar.vue"
import Badge from "components/supplemental/Badge.vue"
import IconLabel from "components/supplemental/IconLabel.vue"

import DossierModal from "custom/modal/DossierModal.vue"
import MessageModal from "custom/modal/MessageModal.vue"
import ProfileModal from "custom/modal/ProfileModal.vue"
import MenuModal from "custom/modal/MenuModal.vue"

import {User} from "store/user/types"

const communication = namespace("communication")

@Component({
	components: {
		ActionButton,
		NavigationBar,
		Badge,
		IconLabel,
		DossierModal,
		MessageModal,
		ProfileModal,
		MenuModal
	}
})
export default class PageHeader extends Vue {
	@Action logout!: StoreAction<void>

	@State active!: boolean
	@State user!: User

	@communication.Getter unreadMessages!: number
	@communication.Getter unreadDocuments!: number
}
