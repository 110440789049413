












import Vue from "vue"
import Component from "vue-class-component"
import {namespace} from "vuex-class"

import eventBus from "lib/vue/eventBus"
import Modal from "components/modal/Modal.vue"
import MessageBox from "custom/MessageBox.vue"
import {Message} from "store/communication/types"

const communication = namespace("communication")

@Component({
	components: {
		MessageBox,
		Modal
	}
})
export default class MessageModal extends Vue {
	@communication.Getter messages!: Array<Message>

	event = "message-modal"
	selectedMessage: Message | null = null

	beforeMount() {
		eventBus.$on(this.event, (message?: Message) => {
			this.selectedMessage = message || this.messages[0]
		})
	}

}
