import Vue from "vue"
import {employees, lifecycles, schemes} from "src/mercerAPI/gateway"

import {sumBy} from "lodash-es"
import groupWith from "lib/iterable/groupWith"
import range from "lib/misc/range"
import scan from "lib/iterable/scan"
import {colors, secondaryColors, formatDuration, formatMoney, formatNumber, formatPercentage, formatDate, periods} from "./format"

import {GraphContent} from "custom/charts/types"
import {Participation, PremiumBase} from "src/mercerAPI/models/scheme"
import {AssetReturn} from "./models/asset"
import {Multilingual} from "./models/lifecycle"

const locale = (code: string) => Vue.filter("locale")(code)
const template = (code: string, context: any) => Vue.filter("template")(locale(code), context)

const multilingualItem = (item: Multilingual) => locale("localeCode") === "nl_NL" ? item.nl : item.en

const chartColor = "#006d9e"
const chartColorSecondary = "#a6e2ef"

export interface BasicSchemeData {
	code: string
	fields: Array<SchemeDataField>
}
export interface PensionSchemeData extends BasicSchemeData {
	scales: Array<SchemeDataStaffel>
}

interface SchemeDataField {
	name: string
	value: string
}
interface SchemeDataStaffel {
	age: string
	scale: string
}

export const ageSalary = async (schemeCode: string): Promise<GraphContent> => {
	const data = await employees.getAverageSalaryByAge(schemeCode)

	return {
		title: locale("employees.agesalary"),
		labels: periods(data.map(item => item.age)),
		datasets: [{
			data: data.map(item => item.salary),
			label: locale("employees.salarydistribution"),
			backgroundColor: chartColor
		}],
		callback: value => formatMoney(value)
	}
}

export const ageGender = async (schemeCode: string): Promise<GraphContent> => {
	const data = await employees.getCountGenderByAge(schemeCode)

	const percentages = data.map(item => {
		const total = item.male + item.female
		return {
			...item,
			male: total > 0 ? Math.round(item.male / total * 100) : 0,
			female: total > 0 ? Math.round(item.female / total * 100) : 0
		}
	})

	return {
		title: locale("employees.agegender"),
		labels: periods(data.map(item => item.age)),
		datasets: [{
			data: percentages.map(item => item.male),
			label: locale("general.male"),
			backgroundColor: chartColor,
			fill: true
		}, {
			data: percentages.map(item => item.female),
			label: locale("general.female"),
			backgroundColor: chartColorSecondary,
			fill: true
		}],
		callback: value => `${value}%`
	}
}

export const premiumAge = async (schemeCode: string): Promise<GraphContent> => {
	const data = await employees.getPremiumByAge(schemeCode)

	return {
		title: locale("employees.premiumage"),
		labels: periods(data.map(item => item.age)),
		datasets: [{
			data: data.map(item => Math.round(item.premium)),
			label: locale("employees.premiumdistribution"),
			backgroundColor: chartColor
		}],
		callback: value => formatMoney(value)
	}
}

export const premiumPercentageSalaryAge = async (schemeCode: string): Promise<GraphContent> => {
	const data = await employees.getPremiumPercentageByAge(schemeCode)

	return {
		title: locale("employees.premiumpercentagesalaryage"),
		labels: periods(data.map(item => item.age)),
		datasets: [{
			data: data.map(item => item.percentage * 100),
			label: locale("employees.premiumdistribution"),
			backgroundColor: chartColor
		}],
		callback: value => formatPercentage(value)
	}
}

export const employmentDuration = async (schemeCode: string): Promise<GraphContent> => {
	const data = await employees.getCountEmploymentDuration(schemeCode)

	return {
		title: locale("employees.periodemployment"),
		labels: periods(data.map(item => item.duration)),
		datasets: [{
			data: data.map(item => item.count),
			label: locale("employees.count"),
			backgroundColor: chartColor
		}],
		callback: value => Math.floor(value) === value ? formatNumber(value) : ""
	}
}

export const trend = async (schemeCode: string, fromYear: number, toYear: number): Promise<Array<Array<string>>> => {
	const data = await employees.getTrend(schemeCode, fromYear, toYear)
	const ages = periods(data.map(item => item.age))

	const difference = (start: number, end: number) => Number((end || 1) / (start || 1) * 100 - 100)

	const header = [
		locale("employees.age"),
		template("employees.countyear", {year: fromYear}),
		locale("employees.countnew"),
		locale("employees.countleft"),
		template("employees.countyear", {year: toYear}),
		locale("employees.difference")
	]
	const content = data.map((row, i) => [
		ages[i],
		formatNumber(row.start),
		formatNumber(row.entered),
		formatNumber(row.left),
		formatNumber(row.end),
		formatPercentage(difference(row.start, row.end))
	])

	const totalStart = sumBy(data, item => item.start)
	const totalEnd = sumBy(data, item => item.end)
	const total = [
		locale("employees.total"),
		formatNumber(totalStart),
		formatNumber(sumBy(data, item => item.entered)),
		formatNumber(sumBy(data, item => item.left)),
		formatNumber(totalEnd),
		formatPercentage(difference(totalStart, totalEnd))
	]

	return [header, ...content, total]
}

export interface FundPerformance {
	name: string
	fund: ReadonlyArray<number>
	years: ReadonlyArray<number>
	benchmark: ReadonlyArray<number>
	managementFee: string
}
// Create a function that takes an array of return percentages and calculates the accumulated geometric means.
// Example: [0.1, 0.2, 0.1] => [0.1, (0.1 * 0.2) ** (1/2), (0.1 * 0.2 * 0.1) ** (1/3)]
const yields = (returns: ReadonlyArray<number>) => scan(
	// First multiply the yields together.
	(prev, performance) => prev * (1 + performance),
	1,
	returns
).map(
	// Compute the geometric mean and subtract 100% to get back to yields.
	(performance, index) => performance ** (1 / (index + 1)) - 1
)

const byYearDesc = (a: AssetReturn, b: AssetReturn) => b.year - a.year
export const lifecycleEfficiency = async (schemeCode: string): Promise<ReadonlyArray<FundPerformance>> => {
	const [scheme, assets] = await Promise.all([
		schemes.getByCode(schemeCode),
		schemes.getAssets(schemeCode)
	])

	return assets.map(
		asset => ({
			name: asset.name,
			fund: yields(asset.performance.sort(byYearDesc).map(p => p.performance)),
			benchmark: yields(asset.performance.sort(byYearDesc).map(p => p.benchmark)),
			years: asset.performance.sort(byYearDesc).map(p => p.year),
			managementFee: formatPercentage(scheme.managementFee * 100, 2)
		})
	)
}

export const behaviour = async (schemeCode: string): Promise<GraphContent> => {
	const data = await schemes.getTotals(schemeCode)

	const totals = [
		{ name: locale("employees.countparticipants"), value: data.count },
		{ name: locale("employees.countuniquelogins"), value: data.unique },
		{ name: locale("employees.countswitchup"), value: data.switchUp },
		{ name: locale("employees.countswitchdown"), value: data.switchDown }
	]

	return {
		title: `${locale("employees.behaviourstats")}${data.referenceDate ? " - " + data.referenceDate.getFullYear() : ""}`,
		labels: totals.map(item => item.name),
		datasets: [{
			data: totals.map(item => item.value),
			label: locale("employees.behaviourstats"),
			backgroundColor: chartColor
		}],
		callback: value => formatNumber(value)
	}
}

export const lifecycleChoices = async (schemeCode: string): Promise<GraphContent> => {
	const [schemeLifecycles, totals] = await Promise.all([
		schemes.getLifecycles(schemeCode),
		schemes.getTotals(schemeCode)
	])

	return {
		title: locale("employees.investmentchoices"),
		labels: schemeLifecycles.map(lifecycle => multilingualItem(lifecycle.name)),
		datasets: [{
			data: schemeLifecycles.map((_, i) => totals.lifecycles[i] || 0),
			label: locale("employees.investmentchoices"),
			backgroundColor: chartColor
		}],
		callback: value => formatNumber(value)
	}
}

export const pensionScheme = async (schemeCode: string): Promise<PensionSchemeData> => {
	const [scheme, scaledata] = await Promise.all([
		schemes.getByCode(schemeCode),
		schemes.getPremiumPrognosis(schemeCode)
	])

	const scales = groupWith((a, b) => a.scale === b.scale, scaledata).map(item =>
		({age: `${item[0].age} - ${item[item.length - 1].age}`, scale: formatPercentage(item[0].scale * 100, 2)})
	)

	const notApplicable = locale("general.notapplicable")

	const fields: Array<SchemeDataField> = [
		{
			name: locale("schemes.type"),
			value: scheme.name
		},
		{
			name: locale("schemes.pensionage"),
			value: formatDuration(scheme.retirementAgeMonths)
		},
		{
			name: locale("schemes.scale"),
			value: locale("schemes.tableReference")
		},
		{
			name: locale("schemes.franchiseop"),
			value: scheme.pension.op.accrual !== Participation.NO ? formatMoney(scheme.pension.op.franchise) : notApplicable
		},
		{
			name: locale("schemes.maxsalary"),
			value: scheme.pension.op.accrual !== Participation.NO && scheme.maxSalary > 0 ? formatMoney(scheme.maxSalary) : notApplicable
		},
		{
			name: locale("schemes.pensionable"),
			value: scheme.pension.op.accrual !== Participation.NO
				? locale("schemes.pensionableformula")
				: notApplicable
		},
		{
			name: locale("schemes.contribution"),
			value: `${formatPercentage(scheme.premium.percentage * 100)} ${scheme.premium.base === PremiumBase.PENSIONABLESALARY ?
				locale("schemes.formPensionGround") : locale("schemes.formPremium")}`
		},
		{
			name: locale("schemes.partnerpension"),
			value: scheme.pension.pp.accrual !== Participation.NO ? formatPercentage(scheme.pension.pp.percentage * 100, 3) : notApplicable
		},
		{
			name: locale("schemes.franchisepp"),
			value: scheme.pension.pp.accrual !== Participation.NO ? formatMoney(scheme.pension.pp.franchise) : notApplicable
		},
		{
			name: locale("schemes.anwgap"),
			value: scheme.pension.anw.accrual !== Participation.NO
				// tslint:disable-next-line: max-line-length
				?	`${scheme.pension.anw.accrual === Participation.OBLIGATORY ? locale("schemes.obligatory") : locale("schemes.voluntary")}, ${formatMoney(scheme.amountAnw)}`
				: locale("general.no")
		},
		{
			name: locale("schemes.disability"),
			value: scheme.pension.excedentAO.accrual !== Participation.NO
				? scheme.pension.excedentAO.accrual === Participation.OBLIGATORY ? locale("schemes.obligatory") : locale("schemes.voluntary")
				: locale("general.no")
		}
	]

	return {
		code: `${scheme.code}`,
		fields,
		scales
	}
}

export const conventionScheme = async (schemeCode: string): Promise<BasicSchemeData> => {
	const [scheme, schemeLifecycles] = await Promise.all([
		schemes.getByCode(schemeCode),
		schemes.getLifecycles(schemeCode)
	])

	const fields: Array<SchemeDataField> = [
		{name: locale("schemes.provider"), value: scheme.provider.name},
		{name: locale("schemes.startdate"), value: formatDate(scheme.startDate)},
		{name: locale("schemes.enddate"), value: scheme.expirationDate ? formatDate(scheme.expirationDate) : locale("schemes.enddateundeterminedtime")},
		{name: locale("schemes.noticeperiod"), value: formatDuration(scheme.noticePeriod)},
		{name: locale("schemes.managementcosts") + "*", value: formatPercentage(scheme.managementFee * 100, 2)},
		{name: locale("schemes.defaultlifecycle"), value: multilingualItem(schemeLifecycles[0].name)}
	]

	return {
		code: scheme.code,
		fields
	}
}

export const schemeManagementFee = async (schemeCode: string): Promise<string> => {
	const scheme = await schemes.getByCode(schemeCode)

	return formatPercentage(scheme.managementFee * 100, 2)
}

export const lifecyclePerformance = async (schemeCode: string, years: number): Promise<GraphContent | null> => {
	const [scheme, lifecyclePerformances] = await Promise.all([
		schemes.getByCode(schemeCode),
		schemes.getPerformance(schemeCode, years)
	])

	const filteredLifecyclePerformances = lifecyclePerformances.filter(item => item.performance.length > 0)
	const colorSpectrum = secondaryColors(filteredLifecyclePerformances.length)

	 // check if we have exactly years amount of data, only show graph then
	if (filteredLifecyclePerformances.length === 0 ||
		(filteredLifecyclePerformances[0].toYear - filteredLifecyclePerformances[0].fromYear) + 1 !== years) {
		return null
	} else {
		return {
			title: template("schemes.performancetitle", {name: scheme.provider.name, year: filteredLifecyclePerformances[0].fromYear}),
			labels: filteredLifecyclePerformances[0].performance.map(item => template("schemes.yearsold", item)),
			datasets: filteredLifecyclePerformances.map((p, index) => ({
				data: p.performance.map(item => (item.yield - scheme.managementFee) * 100),
				label: multilingualItem(p.lifecycle.name),
				backgroundColor: colorSpectrum[index]
			})),
			callback: value => formatPercentage(value, 1)
		}
	}
}

export const lifecycleAllocation = async (lifecycleId: string): Promise<GraphContent> => {
	const lifecycle = await lifecycles.getById(lifecycleId)

	if (lifecycle.allocation === undefined) {
		lifecycle.allocation = []
	}

	const allocation = lifecycle.allocation.map(element => ({
		category: multilingualItem(element.category),
		allocation: element.allocation.map(percentage => percentage * 100)
	}))

	const colorSpectrum = colors(allocation.length)
	const allocationLength = lifecycle.allocation.length ? allocation[0].allocation.length - 1 : 0
	const chartLabels = (allocationLength > 45
			? [...range(0, allocationLength - 45)].reverse().concat(range(1, 45))
			: range(0, allocationLength)
		).map(item => item.toString()).reverse()

	return {
		title: `${locale("schemes.lifecycle")} ${multilingualItem(lifecycle.name)}`,
		labels: chartLabels,
		datasets: allocation.map((item, index) => ({
			data: item.allocation,
			label: item.category,
			backgroundColor: colorSpectrum[index],
			lineTension: 0
		})),
		callback: value => formatPercentage(value)
	}
}

export const lifecycleAllocationDefault = async (schemeCode: string): Promise<GraphContent> => {
	const schemeLifecycles = await schemes.getLifecycles(schemeCode)
	const defaultLifecycleId = schemeLifecycles[0].id

	const data = await lifecycleAllocation(defaultLifecycleId)
	data.title = locale("schemes.defaultlifecycle")
	return data
}

export const lifecycleAllocationAll = async (schemeCode: string): Promise<Array<GraphContent>> => {
	const schemeLifecycles = await schemes.getLifecycles(schemeCode)
	return await Promise.all(schemeLifecycles.map(lifecycle => lifecycleAllocation(lifecycle.id)))
}

export const premiumMarkup = async (schemeCode: string): Promise<GraphContent> => {
	const schemeTotals = await employees.getPremiumMarkup(schemeCode)

	const chartData = [
		{ name: locale("schemes.premiumbase"), value: schemeTotals.base },
		{ name: locale("schemes.npcoverage"), value: schemeTotals.npCoverage },
		{ name: locale("schemes.pvi"), value: schemeTotals.pvi },
		{ name: locale("schemes.admincosts"), value: schemeTotals.administration },
		{ name: locale("schemes.additionalcosts"), value: schemeTotals.additional }
	]

	const filteredChartData = chartData.filter(item => item.value > 0)

	return {
		title: locale("employees.premiumcosttype"),
		labels: filteredChartData.map(item => item.name),
		datasets: [{
			backgroundColor: secondaryColors(filteredChartData.length),
			data: filteredChartData.map(item => item.value)
		}],
		callback: value => formatPercentage(value * 100, 2)
	}
}
