
import Component, { mixins } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import { Bar } from "vue-chartjs"
import { GraphContent } from "custom/charts/types"

@Component
export default class BarChart extends mixins(Bar) {
	@Prop({type: Object, required: true}) graphcontent!: GraphContent
	@Prop({type: Boolean, required: false, default: true}) stacked!: boolean
	@Prop({type: Boolean, required: false, default: true}) beginAtZero!: boolean

	@Watch("graphcontent")
	loadContent() {
		this.renderChart({
			labels: this.graphcontent.labels,
			datasets: this.graphcontent.datasets
		},
		{
			title: {
				display: true,
				text: this.graphcontent.title,
				fontSize: 16
			},
			tooltips: {
				mode: "index",
				intersect: false,
				callbacks: {
					label: (tooltipItems: any, data: any) =>
						// tslint:disable-next-line:max-line-length
						`${data.datasets[tooltipItems.datasetIndex].label}: ${this.graphcontent.callback ? this.graphcontent.callback(tooltipItems.yLabel) : tooltipItems.yLabel}`
				}
			},
			responsive: true,
			scales: {
				xAxes: [{
					stacked: this.stacked
				}],
				yAxes: [{
					stacked: this.stacked,
					ticks: {
						beginAtZero: this.beginAtZero,
						callback: (value: number): string => `${this.graphcontent.callback ? this.graphcontent.callback(value) : value}`
					}
				}]
			},
			maintainAspectRatio: false,
			legend: {
				position: "right",
				reverse: false,
				onClick: null
			},
			plugins: {
				labels: {}
			}
		})
	}

	mounted() {
		this.loadContent()
	}
}
