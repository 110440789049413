









import Hamburger from "components/button/Hamburger.vue"

import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Component({
	components: {
		Hamburger
	}
})
export default class NavigationBar extends Vue {
	@Prop({type: Boolean, required: false, default: false}) responsive!: boolean
	active = false
}
