






import Component from "vue-class-component"
import {namespace} from "vuex-class"

import {ValueLabel} from "components/button/Dropdown.vue"
import DropdownItem from "components/button/DropdownItem.vue"
import InputField from "components/form/InputField"
import DropdownFilter from "custom/DropdownFilter.vue"

import {SchemeCode} from "store/user/types"

const user = namespace("user")

@Component({
	components: {
		DropdownFilter,
		DropdownItem
	}
})
export default class DropdownSchemeFilter extends InputField<string> {
	@user.Getter schemeCodes!: Array<SchemeCode>

	get options(): ReadonlyArray<ValueLabel<string>> {
		return this.schemeCodes.length
			? this.schemeCodes.map(scheme => ({label: scheme.name, value: scheme.code}))
			: [{label: "", value: ""}]
	}
}
