












import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import eventBus from "lib/vue/eventBus"

@Component
export default class ImageCard extends Vue {
	@Prop({required: false, type: String}) href!: string
	@Prop({required: false, type: String}) event!: string
	@Prop({required: false, type: Boolean}) disabled!: boolean

	hasTitle = false
	hasContent = false

	clicked(e: MouseEvent) {
		if (this.href) {
			location.href = this.href
		} else if (!e.defaultPrevented && this.event) {
			eventBus.$emit(this.event)
		}
		this.$emit("click", e)
	}

	mounted() {
		this.hasTitle = !!this.$slots.title
		this.hasContent = !!this.$slots.default
	}
}
