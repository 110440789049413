import {get} from "../request"
import {rootEndPoint} from "../settings"
import {
	BasicEmployee,
	CountGenderByAge,
	AverageSalaryByAge,
	CountEmploymentDuration,
	Trend,
	PremiumByAge,
	PremiumPercentageByAge
} from "../models/employee"
import {PremiumMarkup} from "../models/scheme"

const employeesURL = `${rootEndPoint}/employees`

export interface EmployeeController {
	getMany: () => Promise<Array<BasicEmployee>>
	getAverageSalaryByAge: (schemeCode: string, ages?: Array<number>) => Promise<Array<AverageSalaryByAge>>
	getCountGenderByAge: (schemeCode: string, ages?: Array<number>) => Promise<Array<CountGenderByAge>>
	getCountEmploymentDuration: (schemeCode: string, periods?: Array<number>) => Promise<Array<CountEmploymentDuration>>
	getPremiumByAge: (schemeCode: string, ages?: Array<number>) => Promise<Array<PremiumByAge>>
	getPremiumPercentageByAge: (schemeCode: string, ages?: Array<number>) => Promise<Array<PremiumPercentageByAge>>
	getPremiumMarkup: (schemeCode: string) => Promise<PremiumMarkup>
	getTrend: (schemeCode: string, fromYear: number, toYear: number, ages?: Array<number>) => Promise<Array<Trend>>
	getTrendYears: (schemeCode: string) => Promise<Array<number>>
}

export default class EmployeeGateway implements EmployeeController {
	async getMany(): Promise<Array<BasicEmployee>> {
		return get(employeesURL)
	}
	async getAverageSalaryByAge(schemeCode: string, ages?: Array<number>): Promise<Array<AverageSalaryByAge>> {
		return get(`${employeesURL}/scheme/${schemeCode}/salary/age`, {ages})
	}
	async getCountGenderByAge(schemeCode: string, ages?: Array<number>): Promise<Array<CountGenderByAge>> {
		return get(`${employeesURL}/scheme/${schemeCode}/gender/age`, {ages})
	}
	async getCountEmploymentDuration(schemeCode: string, periods?: Array<number>): Promise<Array<CountEmploymentDuration>> {
		return get(`${employeesURL}/scheme/${schemeCode}/employment/duration`, {periods})
	}
	async getPremiumByAge(schemeCode: string, ages?: Array<number>): Promise<Array<PremiumByAge>> {
		return get(`${employeesURL}/scheme/${schemeCode}/premium/age`, {ages})
	}
	async getPremiumPercentageByAge(schemeCode: string, ages?: Array<number>): Promise<Array<PremiumPercentageByAge>> {
		return get(`${employeesURL}/scheme/${schemeCode}/premium/percentage`, {ages})
	}
	async getPremiumMarkup(schemeCode: string): Promise<PremiumMarkup> {
		return get(`${employeesURL}/scheme/${schemeCode}/premium/markup`)
	}
	async getTrend(schemeCode: string, fromYear: number, toYear: number, ages?: Array<number>): Promise<Array<Trend>> {
		return get(`${employeesURL}/scheme/${schemeCode}/trend/${fromYear}/${toYear}`, {ages})
	}
	async getTrendYears(schemeCode: string): Promise<Array<number>> {
		return get(`${employeesURL}/scheme/${schemeCode}/trend/years`)
	}
}
