







import {Component, Prop} from "vue-property-decorator"

import Dropdown, {ValueLabel} from "components/button/Dropdown.vue"
import InputField from "components/form/InputField"

@Component({
	components: {
		Dropdown
	}
})
export default class DropdownFilter<T> extends InputField<T> {
	@Prop({type: Array, required: true}) options!: ReadonlyArray<ValueLabel<T>>
}
