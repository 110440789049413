






























































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import ImageCard from "custom/ImageCard.vue"

import {Module} from "store/communication/types"
import {namespace } from "vuex-class"
const communication = namespace("communication")
import {hasModule} from "custom/module/hasModule"

@Component({
	components: {
		ImageCard
	}
})
export default class MercerMenu extends Vue {
	@Prop({type: Boolean, default: false}) small!: boolean
	@communication.Getter modules!: Array<Module>

	get showEmployees() { return hasModule(this.modules, "employees.overview") }
	get showControl() { return hasModule(this.modules, "control.overview") }
	get showCommuncation() { return hasModule(this.modules, "communication.overview") }
}
