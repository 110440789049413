import {BasicScheme} from "src/mercerAPI/models/scheme"

export {Provider} from "src/mercerAPI/models/provider"
export {BasicScheme}

export interface User {
	employer: string
	name: string
	email: string
	position: string
	schemes: Array<BasicScheme>
}

export interface SchemeCode {
	name: string,
	code: string
}
