import {ConversionMap} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {alphanumeric} from "lib/import/convert"
import {User, BasicScheme, Provider} from "./types"

const required = true
// const optional = false

const provider: ConversionMap<Provider> = {
	id: ["id", alphanumeric, required],
	code: ["code", alphanumeric, required],
	name: ["name", alphanumeric, required]
}

const scheme: ConversionMap<BasicScheme> = {
	code: ["code", alphanumeric, required],
	name: ["name", alphanumeric, required],
	provider: ["provider", one(provider), required]
}

const user: ConversionMap<User> = {
	employer: ["name", alphanumeric, required],
	schemes: ["schemes", many(scheme), required],
	name: ["nonexistent", () => "Demo", required],
	email: ["nonexistent", () => "demo@keylane.com", required],
	position: ["nonexistent", () => "Manager", required]
}

export const convert = one(user)
