










































import Vue from "vue"
import Component from "vue-class-component"
import {namespace} from "vuex-class"

import IconLabel from "custom/IconLabel.vue"
import MercerMenu from "custom/MercerMenu.vue"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import Card from "components/panel/Card.vue"
import Modal from "components/modal/Modal.vue"

const communication = namespace("communication")

@Component({
	components: {
		ActionButton,
		Badge,
		Card,
		IconLabel,
		MercerMenu,
		Modal
	}
})
export default class MenuModal extends Vue {
	@communication.Getter unreadMessages!: number
	@communication.Getter unreadDocuments!: number
}
