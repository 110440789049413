import {hexToHsl, hslToHex} from "./convert"

export default (n: number, baseHex: string, endHex?: string): ReadonlyArray<string> => {
	const hsl = hexToHsl(baseHex)
	const endHsl = endHex === undefined
		? [hsl[0], hsl[1], 0.9]
		: hexToHsl(endHex)

	const deltaH = (endHsl[0] - hsl[0]) / (n - 1)
	const deltaLMax = 0.1
	const deltaL = (endHsl[2] - hsl[2]) / (n - 1)
	const colorArray = [baseHex]

	let currentH = hsl[0]
	let currentL = hsl[2]
	for (let i = 0; i < n - 1; i++) {
		currentH += deltaH
		currentL += Math.min(deltaLMax, deltaL)
		colorArray.push(hslToHex([currentH, 1, currentL]))
	}

	return colorArray
}
