import {get} from "../request"
import {rootEndPoint} from "../settings"

const providerURL = `${rootEndPoint}/providers`

import {Provider} from "../models/provider"

export interface ProviderController {
	getMany: (skip?: number, limit?: number) => Promise<Array<Provider>>
	getById: (id: string) => Promise<Provider>
}

export default class ProviderGateway implements ProviderController {
	async getMany(skip?: number, limit?: number): Promise<Array<Provider>> {
		return await get(`${providerURL}`, {skip, limit})
	}
	async getById(providerId: string): Promise<Provider> {
		return await get(`${providerURL}/${providerId}`)
	}
}
