import arrayOf from "lib/iterable/arrayOf"

export default <T>(f: (a: T, b: T) => boolean, iterable: Iterable<T>): ReadonlyArray<ReadonlyArray<T>> => {
	const result: Array<Array<T>> = []

	const list = arrayOf(iterable)
	let index = 0
	while (index < list.length) {
		let nextIndex = index + 1
		while (nextIndex < list.length && f(list[nextIndex - 1], list[nextIndex])) {
			nextIndex += 1
		}
		result.push(list.slice(index, nextIndex))
		index = nextIndex
	}
	return result
}
