import {Provider} from "src/mercerAPI/models/provider"
import {BasicLifecycle} from "src/mercerAPI/models/lifecycle"

export interface BasicScheme {
	code: string
	name: string
	provider: Provider
}

export interface Scheme extends BasicScheme {
	startDate: Date
	expirationDate?: Date
	noticePeriod: number
	maxSalary: number
	amountAop: number
	amountAnw: number
	retirementAgeMonths: number
	managementFee: number
	lifecycles: Array<BasicLifecycle>
	referenceDate: Date
	pension: Pension
	premium: SchemePremium
}

export interface SchemePremium {
	base: PremiumBase
	percentage: number
}

export interface PremiumMarkup {
	base: number
	npCoverage: number
	pvi: number
	administration: number
	additional: number
}

export interface PremiumPrognosis {
	age: number
	scale: number
	career: number
	resign: number
	salary: number
	frequency: number
}

export interface Pension {
	redeemable: boolean
	op: PensionOp
	pp: PensionPp
	wzp: PensionWzp
	anw: PensionAnw
	excedentAO: PensionExcedent
}

export enum PremiumBase {
	PENSIONABLESALARY = "PENSIONABLESALARY",
	DCPREMIUM = "DCPREMIUM"
}

export enum Participation {
	NO = "NO",
	VOLUNTARY = "VOLUNTARY",
	OBLIGATORY = "OBLIGATORY"
}

export enum PensionType {
	AVERAGEPAY = "AVARAGEPAY",
	FINALPAY = "FINALPAY"
}

export interface PensionOp {
	accrual: Participation
	franchise: number
	type: PensionType
}

export interface PensionPp {
	accrual: Participation
	franchise: number
	percentage: number
}

export interface PensionWzp {
	accrual: Participation
	percentage: number
}

export interface PensionAnw {
	accrual: Participation
}

export interface PensionExcedent {
	accrual: Participation
	percentage: number
}

export interface Totals {
	referenceDate?: Date
	count: number
	login: number
	optingOut: number
	switchUp: number
	switchDown: number
	unique: number
	lifecycles: Array<number>
}

export interface TotalsLifecycle {
	count: number
	lifecycle: BasicLifecycle
}
