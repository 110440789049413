import {AuthorizationStrategy, UsernamePassword} from "lib/types/security"
import {RequestMethod, Parameters} from "lib/types/request"
import RequestError from "lib/request/RequestError"
import {queryString, encodeParameters} from "lib/request/queryParameters"
import {StorageOptions} from "lib/types/storage"

const DEFAULT_KEY = "auth"

interface Auth {
	readonly username: string
	readonly roles: ReadonlyArray<string>
	readonly access_token: string
	readonly refresh_token: string
	readonly token_type: string
	readonly expires_in: number
}

const isAuth = (data: any): data is Auth =>
	data && data.username && data.roles && data.access_token && data.refresh_token && data.token_type && data.expires_in

export default class JwtAuthorization implements AuthorizationStrategy<UsernamePassword> {
	private _auth: Auth | null = null
	private readonly key: string

	constructor(private readonly loginEndPoint: string, private readonly refreshEndPoint: string, private readonly options: StorageOptions) {
		this.key = options.key || DEFAULT_KEY
		const data = options.storage.retrieve(this.key)
		if (isAuth(data)) {
			this.auth = data
		}
	}

	private get auth() {
		return this._auth
	}

	private set auth(auth: Auth | null) {
		this._auth = auth
		if (auth) {
			this.options.storage.store(this.key, auth)
		} else {
			this.options.storage.discard(this.key)
		}
	}

	isLoggedIn(): boolean {
		return !!this.auth
	}

	isAuthorizedAll(roles: ReadonlyArray<string>): boolean {
		return this.isLoggedIn() && roles.every(role => this.auth!.roles.includes(role))
	}

	isAuthorizedAny(roles: ReadonlyArray<string>): boolean {
		return this.isLoggedIn() && roles.some(role => this.auth!.roles.includes(role))
	}

	async login(credentials: UsernamePassword): Promise<boolean> {
		if (this.isLoggedIn()) {
			return false
		}

		const response = await fetch(this.loginEndPoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Cache-Control": "no-cache"
			},
			credentials: "omit",
			body: JSON.stringify(credentials)
		})

		switch (response.status) {
			case 200: {
				const auth = await response.json()
				if (!isAuth(auth)) {
					throw new TypeError("Invalid token")
				}
				this.auth = auth
				return true
			}
			case 401:
				return Promise.reject(new RequestError(response))
			default:
				throw new RangeError(`Unexpected status code ${response.status}`)
		}
	}

	logout(): Promise<boolean> {
		this.auth = null
		return Promise.resolve(true)
	}

	async request(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		const query = (method === "GET" || method === "HEAD") && data ? queryString(data) : ""
		const response = await this.fetch(method, input + query, data)

		if (response.ok) {
			return response
		}

		switch (response.status) {
			case 401: {
				const refreshed = await this.refreshToken()
				if (refreshed) {
					return this.fetch(method, input + query, data)
				}
				// Fall through
			}
			case 403: {
				this.auth = null
				// Fall through
			}
			default:
				throw new RequestError(response)
		}
	}

	private async refreshToken(): Promise<boolean> {
		if (!this.isLoggedIn()) {
			return false
		}

		const formData = {
			grant_type: "refresh_token",
			refresh_token: this.auth!.refresh_token
		}
		const response = await fetch(this.refreshEndPoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Cache-Control": "no-cache"
			},
			credentials: "omit",
			body: encodeParameters(formData)
		})

		if (response.ok) {
			const data = await response.json()
			const auth = {
				...this.auth,
				...data
			}
			if (isAuth(auth)) {
				this.auth = auth
				return true
			}
		}
		this.auth = null
		return false
	}

	private async fetch(method: RequestMethod, input: string, data?: Parameters): Promise<Response> {
		return await fetch(input, {
			method,
			headers: {
				"Content-Type": "application/json",
				Authorization: this.isLoggedIn() ? `${this.auth!.token_type} ${this.auth!.access_token}` : ""
			},
			mode: "cors",
			credentials: "omit",
			body: method === "GET" || method === "HEAD" || data === undefined ? undefined : JSON.stringify(data)
		})
	}

}
