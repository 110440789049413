



























import Vue from "vue"
import Component from "vue-class-component"
import eventBus from "lib/vue/eventBus"
import DisclaimerModal from "custom/modal/DisclaimerModal.vue"
import CookieModal from "custom/modal/CookieModal.vue"
import PrivacyModal from "custom/modal/PrivacyModal.vue"

@Component({
	components: {
		DisclaimerModal,
		CookieModal,
		PrivacyModal
	}
})
export default class PageFooter extends Vue {
	showModal(event: string) {
		eventBus.$emit(event)
	}
}
