import {cloneDeep} from "lodash-es"
import persist from "lib/vuex/plugins/persist"
import Vue from "vue"
import Vuex, {MutationTree, Store, ActionTree} from "vuex"
import user from "./user/index"
import {convert as convertUser} from "./user/conversion"
import communication from "./communication/index"
import {RootState} from "./types"
import {convertDocuments, convertMessages, convertModules} from "./communication/conversion"
import {session} from "src/mercerAPI/request"
import {employers, content} from "src/mercerAPI/gateway"
import {UsernamePassword} from "lib/types/security"
import load from "src/i18n/load"
import {save as saveLocale} from "lib/i18n/session"
import {storageOptions} from "lib/storage/session"

import eventBus from "lib/vue/eventBus"
import {Introductions} from "src/mercerAPI/models/content"

Vue.use(Vuex)

const rootState: RootState = {
	timestamp: 0,
	lastLogin: null,
	active: false,
	logout: false,
	locale: "",
	introductions: {}
}

const mutations: MutationTree<RootState> = {
	timestamp(state) {
		state.timestamp = Date.now()
	},
	logout(state) {
		state.active = false
		state.logout = true
	},
	login(state) {
		state.active = true
		state.logout = false
	},
	locale(state, locale: string) {
		state.locale = locale
	},
	introductions(state, introductions: Introductions) {
		state.introductions = introductions
	}
}

const actions: ActionTree<RootState, RootState> = {
	async login(context, payload: UsernamePassword): Promise<boolean> {
		const success = await session.login(payload)
		if (success) {
			// Load employer, documents and messages. TODO: move elsewhere (probably to the model layer).
			const [activeUser, documents, messages, modules] = await Promise.all([
				employers.get(),
				employers.documents(),
				employers.messages(),
				employers.modules()
			])
			const pageIntroductions = await content.getMany(activeUser.locale)

			context.commit("user/hydrate", convertUser(activeUser), {root: true})
			context.commit("communication/hydrate", {
				documents: convertDocuments(documents),
				messages: convertMessages(messages),
				modules: convertModules(modules)
			}, {root: true})
			context.commit("locale", activeUser.locale)
			context.commit("introductions", pageIntroductions)
			context.commit("login")
			context.commit("timestamp")

			await saveLocale(load, activeUser.locale)
		}
		return success
	},
	async logout(context): Promise<boolean> {
		await session.logout()
		context.commit("logout")
		return true
	}
}

const plugins = process.env.SERVER ? [] : [
	persist({
		...storageOptions,
		deactivate: s => s.state.logout
	}),
	async () => {
		await Vue.nextTick(() => eventBus.$emit("loaded"))
	}
]

const store = new Store<RootState>({
	modules: {
		user,
		communication
	},
	mutations,
	actions,
	plugins,
	state: cloneDeep(rootState),
	strict: process.env.NODE_ENV !== "production"
})

export default store
