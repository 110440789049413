import between from "lib/number/between"

/** Creates a range of whole numbers  */
export default (from: number, to: number, step: number = 1): ReadonlyArray<number> => {
	const range = []
	if (step !== 0 && between(from, to, from + step)) {
		for (let i = from; step > 0 ? i <= to : i >= to; i += step) {
			range.push(i)
		}
	}

	return range
}
