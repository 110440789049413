






























































import Vue from "vue"
import Component from "vue-class-component"
import {State} from "vuex-class"

import ActionButton from "components/button/ActionButton.vue"
import PersonalData from "components/data/PersonalData.vue"
import Checkbox from "components/form/Checkbox.vue"
import InlineEditable from "components/form/InlineEditable.vue"
import ValidationMessage from "components/form/ValidationMessage.vue"
import PageSection from "components/layout/PageSection.vue"
import Modal from "components/modal/Modal.vue"
import Notice from "components/supplemental/Notice.vue"

import {User} from "store/user/types"

@Component({
	components: {
		ActionButton,
		Checkbox,
		InlineEditable,
		PageSection,
		PersonalData,
		Notice,
		Modal,
		ValidationMessage
	}
})
export default class ProfileModal extends Vue {
	@State user!: User
}
