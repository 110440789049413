import {Document, Message, Module} from "./types"
import {ConversionMap} from "lib/types/import"
import {alphanumeric, bool, datetime} from "lib/import/convert"
import {many} from "lib/import/extract"

const required = true
// const optional = false

const document: ConversionMap<Document> = {
	id: ["id", alphanumeric, required],
	date: ["date", datetime, required],
	title: ["title", alphanumeric, required],
	type: ["type", alphanumeric, required],
	fileName: ["fileName", alphanumeric, required],
	downloaded: ["downloaded", bool, required]
}

const message: ConversionMap<Message> = {
	id: ["id", alphanumeric, required],
	date: ["date", datetime, required],
	title: ["title", alphanumeric, required],
	content: ["content", alphanumeric, required],
	read: ["read", bool, required]
}

const module: ConversionMap<Module> = {
	id: ["id", alphanumeric, required],
	identifier: ["identifier", alphanumeric, required],
	enabled: ["enabled", bool, required]
}

export const convertMessages = many(message)
export const convertDocuments = many(document)
export const convertModules = many(module)
